<template>
	<CustomModal
		:title="$t('Profile.UpdateTitle')"
		:submit-text="$t('Profile.Submit')"
		:cancel-text="$t('Profile.Cancel')"
		size="lg"
		@submit-modal="updateProfile"
		@cancel-modal="cancel"
	>
		<template #content>
			<div class="change-pwd-form">
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(updateProfile)">
						<div class="form-group row mb-0 mb-md-4">
							<div class="col-md-3 col-12 col-form-label">
								{{ $t("Profile.Email") }}
							</div>
							<div class="col-12 col-md-6 col-form-label text-muted">
								{{ currentUser.email }}
							</div>
						</div>

						<!-- User name -->
						<ValidationProvider v-slot="{ classes, errors }" name="auth_full_name" rules="required">
							<div class="form-group row mb-0 mb-md-2 required">
								<label for="full-name" class="col-md-3 col-12 col-form-label">
									{{ $t("Profile.Name") }}
								</label>
								<div :class="['col-12 col-md-6', classes]">
									<div class="position-relative">
										<input
											id="full-name"
											v-model.trim="updateForm.fullName"
											class="form-control custom-input"
											:placeholder="$t('Profile.Name')"
											type="text"
											autocomplete="name"
											:maxlength="nameMaxLength"
										/>
									</div>
									<div class="error-mess">
										{{ errors[0] }}
									</div>
								</div>
							</div>
						</ValidationProvider>

						<!-- Phone number -->
						<div class="form-group row mb-0 mb-md-4">
							<div class="col-md-3 col-12 col-form-label">
								{{ $t("Profile.ManagePK") }}
							</div>
							<div class="col-12 col-md-6 col-form-label text-muted">
								{{ (currentUser.clinic && currentUser.clinic.name) || "" }}
							</div>
						</div>

						<div class="form-group row mb-0 mb-md-4">
							<div class="col-md-3 col-12 col-form-label">
								{{ $t("Profile.Role") }}
							</div>
							<div class="col-8 col-md-6 col-form-label text-muted">
								{{ currentUser.role ? role[currentUser.role] : "" }}
							</div>
						</div>

						<div class="form-group row mb-0 mb-md-4">
							<div class="col-md-3 col-12 col-form-label">
								{{ $t("Profile.Status") }}
							</div>
							<div class="col-8 col-md-6 col-form-label text-muted">
								{{ $t(`Profile.${capitalizeFirstLetter(currentUser.status)}`) }}
							</div>
						</div>
					</form>
				</ValidationObserver>
			</div>
		</template>
	</CustomModal>
</template>

<script>
import { UPDATE_USER_LOGIN_PROFILE } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("authentication")
import { _USER_ROLE } from "../../../shared/plugins/constants"

export default {
	name: "UpdateProfile",

	props: {
		currentUser: {
			type: [Object, Array],
			default: null,
		},
	},

	data() {
		return {
			nameMaxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			updateForm: {
				fullName: this.currentUser?.fullName || null,
			},
			role: _USER_ROLE,
		}
	},

	computed: {},

	watch: {},

	methods: {
		...mapActions({ UPDATE_USER_LOGIN_PROFILE }),
		updateProfile: async function () {
			const valid = await this.$refs.form.validate()
			if (!valid) return

			await this.UPDATE_USER_LOGIN_PROFILE({ ...this.updateForm })
		},
		cancel: function () {
			this.$emit("hide")
		},
		capitalizeFirstLetter(string) {
			const _string = string.toLowerCase()
			return _string.charAt(0).toUpperCase() + _string.slice(1)
		},
	},
}
</script>
<style lang="scss" scoped>
.update-profile-form {
	margin-top: 20px;

	.form-group {
		margin-bottom: 0;
	}
}
</style>
